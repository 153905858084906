import React, { useState } from 'react'
import face from '../../images/assets/BrandIdentity/weworkface.png'
import basic from '../../images/assets/BrandIdentity/basic.svg'
import standard from '../../images/assets/BrandIdentity/standard.svg'
import premium from '../../images/assets/BrandIdentity/premium.svg'
import pointline from '../../images/assets/BrandIdentity/point-basic.svg'
import pointlinemob from '../../images/assets/BrandIdentity/point-basic-phone.svg'
import pointpremium from '../../images/assets/BrandIdentity/point-premium.png'
import { Modal, ModalBody } from 'react-bootstrap'
import { ModalHeader } from 'react-bootstrap'
import basicmodal from '../../images/assets/BrandIdentity/basicmodal.png'
import standardmodal from '../../images/assets/BrandIdentity/standardmodal.svg'
import premiummodal from '../../images/assets/BrandIdentity/premiummodal.svg'
import backarrow from '../../images/assets/BrandIdentity/backarrow.png'
import checkimage from '../../images/digitalmodeltick.png'
import rocketimg from '../../images/assets/BrandIdentity/rocket.svg'
import time from '../../images/assets/BrandIdentity/time.svg'
import check from '../../images/assets/BrandIdentity/checkmark.png'

const WeWorkDM = () => {

    const [showBasicModal, setshowBasicModal] = useState(false)
    const [showStandardModal, setshowStandardModal] = useState(false)
    const [showPremiumModal, setshowPremiumModal] = useState(false)



    const basicsteps = [
        {
            check: checkimage,
            heading: 'Initial Questionaries',
        },
        {
            check: checkimage,
            heading: 'Consultation Session 1',
        },
        {
            check: checkimage,
            heading: 'Market Research',
        },
        {
            check: checkimage,
            heading: 'Competitor analysis',
        },
        {
            check: checkimage,
            heading: 'Brain Storming Consultation Session 2',
        },
        {
            check: checkimage,
            heading: 'Audit/Strategy',
        },
        {
            check: checkimage,
            heading: 'Content Calendar',
        },
        {
            check: checkimage,
            heading: 'Designing',
        },
        {
            check: checkimage,
            heading: 'Posting/Scheduling (Platforms: FB, Insta)',
        },
        {
            check: checkimage,
            heading: 'Ad research',
        },
        {
            check: checkimage,
            heading: 'Ads management',
        },
        {
            check: checkimage,
            heading: 'Monthly Reporting',
        },

    ]

    const standardsteps = [
        {
            check: checkimage,
            heading: 'Initial Questionaries',
        },
        {
            check: checkimage,
            heading: 'Consultation Session 1',
        },
        {
            check: checkimage,
            heading: 'Market Research',
        },
        {
            check: checkimage,
            heading: 'Competitor analysis',
        },
        {
            check: checkimage,
            heading: 'Brain Storming Consultation Session 2',
        },
        {
            check: checkimage,
            heading: 'Audit/Strategy',
        },
        {
            check: checkimage,
            heading: 'Content Calendar',
        },
        {
            check: checkimage,
            heading: 'Designing',
        },
        {
            check: checkimage,
            heading: 'Posting/Scheduling (Platforms: FB, Insta)',
        },
        {
            check: checkimage,
            heading: 'Ad research',
        },
        {
            check: checkimage,
            heading: 'Ads management',
        },
        {
            check: checkimage,
            heading: 'Monthly Reporting',
        },
        {
            check: checkimage,
            heading: 'Website Management',
        },
        {
            check: checkimage,
            heading: 'SEO',
        },
    ]

    const premiumsteps = [
        {
            check: checkimage,
            heading: 'Initial Questionaries',
        },
        {
            check: checkimage,
            heading: 'Consultation Session 1',
        },
        {
            check: checkimage,
            heading: 'Market Research',
        },
        {
            check: checkimage,
            heading: 'Competitor analysis',
        },
        {
            check: checkimage,
            heading: 'Brain Storming Consultation Session 2',
        },
        {
            check: checkimage,
            heading: 'Audit/Strategy',
        },
        {
            check: checkimage,
            heading: 'Content Calendar',
        },
        {
            check: checkimage,
            heading: 'Designing',
        },
        {
            check: checkimage,
            heading: 'Posting/Scheduling (Platforms: FB, Insta)',
        },
        {
            check: checkimage,
            heading: 'Ad research',
        },
        {
            check: checkimage,
            heading: 'Ads management',
        },
        {
            check: checkimage,
            heading: 'Monthly Reporting',
        },
        {
            check: checkimage,
            heading: 'Website Management',
        },
        {
            check: checkimage,
            heading: 'SEO',
        },
        {
            check: checkimage,
            heading: 'Google Ads',
        },
    ]
    const premiumDeliverables = [
        {
            para: 'Extensive Brand Style Guide with do’s and don’ts',
        },
        {
            para: 'Logo with mockups',
        },
        {
            para: 'All files of Logo (PNG, JPEG, Source file, HD File)',
        },
        {
            para: 'Branding Presentation (8-10 Mockups)',
        },
        {
            para: 'Business Card Design + 5 people Printable file for business cards',
        },
        {
            para: 'Social Platforms Dp & Cover (fb,  Insta, LinkedIn, X)',
        },
        {
            para: 'Letterhead Design (Print Ready File)',
        },
        {
            para: 'Small Envelope Design (Print Ready File)',
        },
        {
            para: 'Pen Design (Print Ready File)',
        },
        {
            para: 'Clock Design (Print Ready File)',
        },
        {
            para: 'Stamp Design (Print Ready File)',
        },

    ]
    return (
        <>
            <div className="wework-main">
                <div className="wework-context">
                    <img src={face} alt="" />
                    <h1>How we work?</h1>
                    <p>If you’re creating a start-up, building a small business, or dreaming bigger, Tacit can help you create a unique brand identity.</p>
                </div>

                <div className='digital-package-basic'>
                    <img src={basic} alt="" />
                    <h2>Basic Package</h2>
                    <p>Kickstart your online presence with our Basic Package. Includes Competitor Analysis, Social Media Page Management, and Media Buying</p>
                    <button onClick={() => setshowBasicModal(true)}>More Info</button>
                    <img src={pointline} alt="" onClick={() => setshowBasicModal(true)} style={{ cursor: 'pointer', zIndex: '2' }} className='line-web' />
                    <img src={pointlinemob} alt="" onClick={() => setshowBasicModal(true)} style={{ cursor: 'pointer' }} className='line-mob' />
                    <div className='gradient-left' />
                </div>
                <div className='digital-package-standard'>
                    <img src={pointlinemob} alt="" style={{ rotate: '180deg', cursor: 'pointer' }} onClick={() => setshowStandardModal(true)} className='line-mob' />
                    <img src={pointline} alt="" style={{ rotate: '180deg', cursor: 'pointer' }} onClick={() => setshowStandardModal(true)} className='line-web' />
                    <img src={standard} alt="" />
                    <h2>Standard Package</h2>
                    <p>Expand your reach with our Standard Package. Features Advanced Social Media Management, SEO Strategies, and Website Management</p>
                    <button onClick={() => setshowStandardModal(true)}>More Info</button>
                    <div className='gradient-mid' />
                </div>
                <div className='digital-package-premium'>
                    <img src={premium} alt="" />
                    <h2>Premium Package</h2>
                    <p>Unlock the Full Potential of Digital Marketing with our Premium Package. Includes top-tier Social Media Management, Comprehensive SEO, Website Management and Google Ads </p>
                    <button onClick={() => setshowPremiumModal(true)}>More Info</button>
                    <img src={pointpremium} alt="" onClick={() => setshowPremiumModal(true)} style={{ cursor: 'pointer' }} className='line-web' />
                    <img src={pointlinemob} alt="" onClick={() => setshowPremiumModal(true)} style={{ cursor: 'pointer' }} className='line-mob' />
                </div>
            </div>


            <Modal
                show={showBasicModal} className='digitalbasicmodal'>
                <ModalHeader>
                    <div className='header-text'>
                        <img src={basicmodal} alt="" />
                        <p>Basic Package</p>
                    </div>
                    <img src={backarrow} alt="" onClick={() => setshowBasicModal(false)} style={{ cursor: 'pointer' }} />
                </ModalHeader>
                <ModalBody>
                    <div className='digital-modal-body-left'>
                        <h2 className='digital-modal-heading'>HOW WE WORK</h2>
                        {basicsteps.map((basicsteps, index) => (
                            <div key={index} className='digital-modal-steps'>
                                <div className='steps-left'>
                                    <img src={basicsteps.check} alt="" />
                                    <h2>{basicsteps.heading}</h2>
                                </div>
                            </div>
                        ))}
                        <div className='digital-right-lower'>
                            <div className="subsection-section">
                                <h2>$799</h2>
                                <p>Per Month</p>
                            </div>
                            <a href="https://buy.stripe.com/9AQ6s65zW6qPbQc00L" target="_blank" rel="noopener noreferrer"><button>Buy Now</button></a>
                        </div>
                    </div>
                    {/* <div className='modal-body-right'>
                        <div className='right-upper'>
                            <img src={time} alt="" />
                            <h2>Approximate Time</h2>
                            <p>5 Working Days</p>
                        </div>
                        <div className='right-middle'>
                            <h2>Deliverables:</h2>
                            {Deliverables.map((Deliverables, index) => (
                                <div key={index} className='right-middle-subsection'>
                                    <img src={check} alt='' />
                                    <p>{Deliverables.para}</p>
                                </div>

                            ))}
                        </div> */}

                    {/* </div> */}
                </ModalBody>
            </Modal>


            <Modal
                show={showStandardModal} className='digitalstandardmodal'>
                <ModalHeader>
                    <div className='header-text'>
                        <img src={standardmodal} alt="" />
                        <p>Standard Package</p>
                    </div>
                    <img src={backarrow} alt="" onClick={() => setshowStandardModal(false)} style={{ cursor: 'pointer' }} />
                </ModalHeader>
                <ModalBody>
                    <div className='digital-modal-body-left'>
                        <h2 className='digital-modal-heading'>HOW WE WORK</h2>
                        {standardsteps.map((standardsteps, index) => (
                            <div key={index} className='digital-modal-steps'>
                                <div className='steps-left'>
                                    <img src={standardsteps.check} alt="" />
                                    <h2>{standardsteps.heading}</h2>
                                </div>
                            </div>
                        ))}
                        <div className='digital-right-lower'>
                            <div className="subsection-section">
                                <h2>$1499</h2>
                                <p>Per Month</p>
                            </div>
                            <a href="https://buy.stripe.com/7sIbMq9QcaH57zW6pa" target="_blank" rel="noopener noreferrer"><button>Buy Now</button></a>
                        </div>
                    </div>
                    {/* <div className='modal-body-right'>
                        <div className='right-upper'>
                            <img src={time} alt="" />
                            <h2>Approximate Time</h2>
                            <p>2 weeks</p>
                        </div>
                        <div className='right-middle'>
                            <h2>Deliverables:</h2>
                            {standardDeliverables.map((standardDeliverables, index) => (
                                <div key={index} className='right-middle-subsection'>
                                    <img src={check} alt='' />
                                    <p>{standardDeliverables.para}</p>
                                </div>

                            ))}
                        </div> */}

                    {/* </div> */}
                </ModalBody>
            </Modal>

            <Modal
                show={showPremiumModal} className='digitalpremiummodal'>
                <ModalHeader>
                    <div className='header-text'>
                        <img src={premiummodal} alt="" />
                        <p>Premium Package</p>
                    </div>
                    <img src={backarrow} alt="" onClick={() => setshowPremiumModal(false)} style={{ cursor: 'pointer' }} />
                </ModalHeader>
                <ModalBody>
                    <div className='digital-modal-body-left'>
                        <h2 className='digital-modal-heading'>HOW WE WORK</h2>
                        {premiumsteps.map((premiumsteps, index) => (
                            <div key={index} className='digital-modal-steps'>
                                <div className='steps-left'>
                                    <img src={premiumsteps.check} alt="" />
                                    <h2>{premiumsteps.heading}</h2>
                                </div>
                            </div>
                        ))}
                        <div className='digital-right-lower'>
                            <div className="subsection-section">
                                <h2>$2599</h2>
                                <p>Per Month</p>
                            </div>
                            <a href="https://buy.stripe.com/aEU7wa2nK5mL5rO28V" target="_blank" rel="noopener noreferrer"><button>Buy Now</button></a>
                        </div>
                    </div>
                    {/* <div className='modal-body-right'>
                        <div className='right-upper'>
                            <img src={time} alt="" />
                            <h2>Approximate Time</h2>
                            <p>4 Weeks</p>
                        </div>
                        <div className='right-middle'>
                            <h2>Deliverables:</h2>
                            {premiumDeliverables.map((premiumDeliverables, index) => (
                                <div key={index} className='right-middle-subsection'>
                                    <img src={check} alt='' />
                                    <p>{premiumDeliverables.para}</p>
                                </div>

                            ))}
                        </div> */}

                    {/* </div> */}
                </ModalBody>
            </Modal>
        </>
    )
}

export default WeWorkDM
