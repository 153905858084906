import React, { useState } from 'react';
import toppic from '../images/assets/toppic.png'
import dash from '../images/assets/dash.svg'
import arista from '../images/arista solar branding cover.jpg'
import ibrahimi from '../images/ibrahimi.jpg'
import lubeone from '../images/lubeone.png'
import niche from '../images/niche.png'
import one from '../images/one.png'
import uci from '../images/uci.png'
import andrew from '../images/andrew.png'
import juicy from '../images/juicy.png'
import aristasolar from '../images/aristasolar.png'
import niche2 from '../images/niche2.png'
import terracosta from '../images/terracosta.png'
import ansari from '../images/ansari.png'
// import slider from '../images/slider.png'
// import abstract from '../images/abstract.png'
// import fb from '../images/fb.png'
// import amarlodges from '../images/Amarlodges.png'
// import mustard from '../images/mustard.png'
// import juicy2 from '../images/juicy 2.png'
// import last1 from '../images/last1.png'
// import last2 from '../images/last2.png'
// import last3 from '../images/last3.png'
// import last4 from '../images/last4.png'
// import last5 from '../images/last5.png'
// import last6 from '../images/last6.png'

import WorkSlider from '../Components/WorkSlider';

const Work = () => {

    return (
        <>
            <section id='header2'>
                <div className='work-wrapper'>
                    <div className='content'>
                        <h1><span className='firstline'>your Unboxing</span><br></br><span style={{ color: 'black' }}>Destination for Success</span></h1>
                        <p className='my-3'>Design, Create, Innovate With  &nbsp; <span className='bolder'>Tacit!</span></p>
                    </div>
                    <div className='header-img-container'>
                        <img src={toppic} className='workhead' style={{ position: 'relative', zIndex: '-10' }} alt=''/>
                    </div>
                </div>
            </section>

            <WorkSlider/>

            <div className='work2'>
                <div className='worktxt'>
                    <h1>Where Customized Branding Creates Timeless Success Stories</h1>
                    <img src={dash} className='dash' style={{ marginTop: '20px', width: '68px' }} alt=''/>
                    <p>Experience the power of strategic design, compelling storytelling, and seamless integration that sets the stage for your brand's enduring success.</p>
                </div>
                <div className='workpro'>
                    <div className='profile'>
                        <img src={arista} className='picture' alt=''/>
                        <a href="/Arista" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={ibrahimi} className='picture' alt=''/>
                        <a href="/Ibrahimi" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={lubeone} className='picture' alt=''/>
                        <a href="/Lube" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                </div>
                <div className='workpro'>
                    <div className='profile'>
                        <img src={niche} className='picture' alt=''/>
                        <a href="/Niche" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={one} className='picture' alt=''/>
                        <a href="/One" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={uci} className='picture' alt=''/> 
                        <a href="/UCI" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                </div>
            </div>
            <div className='work3'>
                <div className='worktxt'>
                    <h1>Tacit-IT: Where Creativity Transforms Brands</h1>
                    <img src={dash} className='dash' style={{ marginTop: '20px', width: '68px' }} alt=''/>
                    <p>With a blend of creativity and expertise, we create designs that leave a lasting impression and elevate your brand to new heights.</p>
                </div>
                <div className='workpro'>
                    <div className='profile'>
                        <img src={andrew} className='picture' alt=''/>
                        <a href="/Andrew" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={juicy} className='picture' alt=''/>
                        <a href="/Juicy" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={aristasolar} className='picture' alt=''/>
                        <a href="/Arista" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                </div>
                <div className='workpro'>
                    <div className='profile'>
                        <img src={niche2} className='picture' alt=''/>
                        <a href="/Niche" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={terracosta} className='picture' alt=''/>
                        <a href="/Terracosta" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={ansari} className='picture' alt=''/>
                        <a href="/Ansari" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                </div>
            </div>
            {/* <div className='work4'>
                <div className='worktxt'>
                    <h1>Amplify Your Brand's Reach with Tacit's Social Strategies</h1>
                    <img src={dash} className='dash' style={{ marginTop: '20px', width: '68px' }} />
                    <p>Our team of experts will fuel engagement, drive results, and transform your online presence into a buzzing community of loyal followers.</p>
                </div>
                <div className='workpro'>
                    <div className='profile'>
                        <img src={slider} className='picture' alt=''/>
                        <a href="https://www.behance.net/gallery/110626167/Niche-Furniture"target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={abstract} className='picture' alt=''/>
                        <a href="https://www.behance.net/gallery/88941003/Nile-Travels-Re-Branding" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={fb} className='picture' alt=''/>
                        <a href="https://www.behance.net/gallery/76440391/Slider-Zone" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                </div>
                <div className='workpro'>
                    <div className='profile'>
                        <img src={amarlodges} className='picture' alt=''/>
                        <a href="https://www.behance.net/gallery/111094281/Smart-Motorways" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={mustard} className='picture' alt=''/>
                        <a href="https://www.behance.net/gallery/79387755/Terracosta-Branding" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={juicy2} className='picture' alt=''/>
                        <a href="https://www.behance.net/gallery/72475657/Ansari-fabrics-Branding" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                </div>
            </div>
            <div className='work5'>
                <div className='worktxt'>
                    <h1> Setting Your Brand Apart in the Digital Jungle</h1>
                    <img src={dash} className='dash' style={{ marginTop: '20px', width: '68px' }} />
                    <p>Our expert team crafts engaging narratives that captivate your audience, driving brand awareness and delivering measurable results.</p>
                </div>
                <div className='workpro'>
                    <div className='profile'>
                        <img src={last1} className='picture' alt=''/>
                        <a href="https://www.behance.net/gallery/71298343/Falcon-Travel-Re-Branding" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={last2} className='picture' alt=''/>
                        <a href="https://www.behance.net/gallery/72114375/Horizon-Pools-Branding" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={last3} className='picture' alt=''/>
                        <a href="https://www.behance.net/gallery/71527711/Juicy-Grill-Branding" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                </div>
                <div className='workpro'>
                    <div className='profile'>
                        <img src={last4} className='picture' alt=''/>
                        <a href="https://www.behance.net/gallery/79036063/Andrew-Paints-Re-Branding" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={last5} className='picture' alt=''/>
                        <a href="https://www.behance.net/gallery/79387755/Terracosta-Branding" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                    <div className='profile'>
                        <img src={last6} className='picture' alt=''/>
                        <a href="https://www.behance.net/gallery/110626167/Niche-Furniture" target="_blank" rel="noopener noreferrer"><button>VIEW MORE &nbsp; <span>&#8594;</span></button></a>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Work
