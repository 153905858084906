import React from 'react'
import { useState, useRef } from 'react'
import dash from '../images/assets/dash.svg'
import CountrySelector from '../Components/Country Selector/CountrySelector'
import emailus from '../images/assets/emailicon.svg'
import callus from '../images/assets/callusicon.svg'

const Contact = () => {
  const [showForm, setShowForm] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);
  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    services: '',
    message: '',
    agreement: false,
  });
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };
  const [errors, setErrors] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateForm(formData);
    console.log(Object.keys(validationErrors).length)
    if (Object.keys(validationErrors).length === 0) {
      setShowForm(false);
      setShowThankYou(true);
      const config = {
        SecureToken: '60d04b0a-78c3-401b-8683-4721c8069cd7',
        To: 'info@wurfelit.co',
        From: "getqoute@wurfelit.co",
        Subject: 'CONTACT FORM',
        Body: `Name :${formData.name}, 
             Email: ${formData.email}, 
             Company: ${formData.services}, 
             Country: ${formData.country.value}, 
             Phone: ${formData.phoneNumber}, 
             MESSAGE : ${formData.message}`
      }
      window.Email.send(config).then((e, c, p, d) => console.log(e, c, p, d))
    } else {
      setErrors(validationErrors);
    }
  };
  const validateForm = (data) => {
    const errors = {};

    if (!data.name || data.name.length < 3 || data.name.length > 25) {
      errors.name = 'Name should be between 3 and 25 characters*';
    }

    if (!data.email) {
      errors.email = 'Email is required*';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Invalid email format*';
    }

    if (!data.country) {
      errors.country = 'Country is required*';
    }
    if (!data.services) {
      errors.services = 'Services field is required';
    }
    if (!data.phoneNumber) {
      errors.phoneNumber = 'Phone number is required*';
    } else if (!/^\d{10}$/.test(data.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number format';
    }
    if (!data.agreement) {
      errors.agreement = 'You must agree to the terms and conditions';
    }
    if (!data.message) {
      errors.message = 'Message field is required';
    }
    return errors;
  };

  return (
    <>
      {showForm && (
        <div className='contact1'>
          <div className='contact1txt'>
            <h1>Contact our team</h1>
            <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} alt='' />
            <p>Just fill the form below and our team will get to you Asap!</p>
          </div>

          <div className='contact1form'>
            <form ref={form} onSubmit={handleSubmit} className='form'>
              <div className='formcon'>
                <div className='formdata'>
                  <label htmlFor="Name">Your Name*</label> {errors.name && <span className="error">{errors.name}</span>}

                  <input type="text"
                    value={formData.name}
                    onChange={handleChange}
                    name="name"
                    placeholder='Enter Your Name'
                    className='name' />
                </div>
                <div className='formdata'>
                  <label htmlFor="Email">Contact email* </label> {errors.email && <span className="error">{errors.email}</span>}

                  <input type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder='You@example.com'
                    className='email' />
                </div>
              </div>
              <div className='formcon'>
                <div className='formdata'>
                  <label htmlFor="Number">Phone Number* </label> {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
                  <div className='inputs'>
                    <CountrySelector setData={setFormData} data={formData} />
                    <input type="text"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      placeholder='xxxxxxxxxx'
                      className='input2' />
                  </div>
                </div>
                <div className='formdata'>
                  <label htmlFor="Services">Services Required*</label> {errors.services && <span className="error">{errors.services}</span>}
                  <select
                    className='servicedrop'
                    name="services"
                    value={formData.services}
                    defaultValue=""
                    onChange={handleChange}
                  >
                    <option value="" disabled>Enter Your Value</option>
                    <option value="Branding">Branding</option>
                    <option value="Web Development">Web Development</option>
                    <option value="Social Media Marketing">Social Media Marketing</option>
                    <option value="Graphic Designing">Graphic Designing</option>
                    <option value="Content Marketing">Content Marketing</option>
                    <option value="Content Marketing">Brand Identity</option>
                  </select>
                </div>
              </div>
              <div className='formcon2'>
                <div className='formdata'>
                  <label htmlFor="Services">Your Message*</label> {errors.message && <div className='error'>{errors.message}</div>}
                  <input
                    type="text"
                    name="message"
                    style={{ verticalAlign: 'text-top' }}
                    placeholder='Type your message...'
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {errors.agreement && <span className="error">{errors.agreement}</span>}
              <div className='text'>
                <input type="checkbox"
                  name='agreement'
                  checked={formData.agreement}
                  onChange={handleChange}
                  className='checkbox' />

                By submitting this form, you hereby acknowledge and agree to the terms and conditions, as well as our privacy policy. Please be aware that we may collect and utilize your personal information as outlined in our privacy policy.
              </div>
              <button type='submit' className='sendbutton' >Send</button>
            </form>
          </div>
        </div>
      )}
      {showForm && (
        <div className='contact2'>
          <div className='contactus'>
            <div className='image'><img src={emailus} alt='' /></div>
            <h2>Email us</h2>
            <h3>Send us an email to get in touch with our team and discuss your inquiries. </h3>
            <p><a href="mailto:info@tacit.com" style={{ textDecoration: 'none' }}>info@tacit.com</a></p>
          </div>
          <div className='contactus'>
            <div className='image'><img src={callus} alt='' /></div>
            <h2>call us</h2>
            <h3>Reach out to us directly <br /> by giving us a ring.   </h3>
            <p><a href="tel: +13073234769">+1 (307) 3234769</a></p>
          </div>
        </div>
      )}
      {showThankYou && (
        <div className='thankyou'>
          <div className='thankyoutxt'>
            <h1>Thank You</h1>
            <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} alt='' />
            <p>We appreciate the time you've taken to get in touch and are committed
              to responding as quickly as possible. Our team will review your message and you
              can expect to hear back from us within <b>24-48 hours</b></p>
          </div>
        </div>
      )}
    </>
  )
}

export default Contact;