// import React from 'react'
// import ServiceCard from '../Components/Servicecard'
// import socialmkt from '../images/assets/socialmkt.png'
// import dash from '../images/assets/dash.svg'
// import mkt1 from '../images/assets/mkt1.svg'
// import mkt2 from '../images/assets/mkt2.svg'
// import mkt3 from '../images/assets/mkt3.svg'
// import mkt4 from '../images/assets/mkt4.svg'
// import mkt5 from '../images/assets/mkt5.svg'
// import mkt6 from '../images/assets/mkt6.svg'
// import mkt7 from '../images/assets/mkt7.svg'
// import mkt8 from '../images/assets/mkt8.svg'
// import mkt9 from '../images/assets/mkt9.svg'
// import port from '../images/assets/port.png'
// import cons from '../images/assets/cons.png'
// const SMM = () => {
//   return (
//     <>
//       <section id='header' className='d-flex align-items-center'>
//         <div className='service1'>
//           <img src={socialmkt} className='branding' style={{ position: 'relative', zIndex: '-10' }} />
//         </div>
//       </section>
//       <div className='service2'>
//         <div className='service2txt'>
//           <h1>Creating Brands that Resonate</h1>
//           <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
//           <p>Branding is not just a logo or tagline; it's an identity. It is proven by the fact that brand awareness is the top goal for 89% of marketers. Trust in a brand encourages 81% of consumers to purchase, and consistent branding can increase revenues by 23%.</p>
//         </div>
//         <div className="service2con">
//           <div className="condiv">
//             <ServiceCard
//               imageSrc={mkt1}
//               h1="Social Media Strategy"
//               p="We aim to align this strategy with your broader business objectives, making your social media presence a strategic asset."
//             />
//             <ServiceCard
//               imageSrc={mkt4}
//               h1="Social Media Advertising"
//               p="We strategically leverage the promotional capabilities of different social platforms to increase brand awareness and promote your products or services."
//             />
//             <ServiceCard
//               imageSrc={mkt7}
//               h1="Social Listening & Monitoring"
//               p="Tacit employs social listening tools to monitor and analyze conversations about your brand across social media."
//             />
//           </div>
//           <div className="condiv">
//             <ServiceCard
//               imageSrc={mkt2}
//               h1="Content Creation & Curation"
//               p="Our creative team develops engaging, relevant, and unique content tailored to your brand’s personality and your audience's preferences."
//             />
//             <ServiceCard
//               imageSrc={mkt5}
//               h1="Audience Engagement"
//               p="We handle all forms of engagement on your social media platforms, sparking conversations, responding to comments, and managing your online community."
//             />
//             <ServiceCard
//               imageSrc={mkt8}
//               h1="Reputation Management"
//               p="We do this through regular monitoring and swift, professional responses to both positive and negative customer feedback."
//             />
//           </div>
//           <div className="condiv">
//             <ServiceCard
//               imageSrc={mkt3}
//               h1="Social Media Management"
//               p="Tacit simplifies this process by managing all your social media accounts, ensuring consistent posting, and timely response to comments."
//             />
//             <ServiceCard
//               imageSrc={mkt6}
//               h1="Influencer Marketing"
//               p="Our team at Tacit is experienced in identifying, reaching out, and negotiating with influencers that resonate with your brand and target demographic."
//             />
//             <ServiceCard
//               imageSrc={mkt9}
//               h1="Social Media Analytics & Reporting"
//               p="We provide insights into audience growth, engagement, reach, and conversions, aiding in strategy adjustments and future planning."
//             />
//           </div>
//         </div>
//       </div>
//       <div className='service3'>
//         <div className='service3txt'>
//           <h1>Connect with Tacit today and let's shape your social media success story.</h1>
//           <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
//           <p>We believe that social media marketing is not a one-size-fits-all approach. That's why we offer customized solutions, tailored to your specific needs and industry. Whether you're a startup looking for growth or an established brand aiming for greater reach and engagement, Tacit is here to make your social media presence a significant factor in your success.
//             <br />
//             <br />
//             Join us, and let's start the journey to your social media success today!</p>
//         </div>
//         <div className='request'>
//           <a href="/Work"><button className='req'>Check our portfolio <img src={port} className='reqimg' /></button></a>
//           <a href="/Contact"><button className='req'>Free Consultation <img src={cons} className='reqimg' /></button></a>
//         </div>
//       </div>

//     </>
//   )
// }

// export default SMM
import React, { useEffect, useRef, useState } from 'react'
import '../Pages/Digital Marketing/DigitalBrand.css'
import dash from '../images/assets/dash.svg'
import headervideo from '../images/assets/BrandIdentity/header-video.mp4'
import left from '../images/assets/BrandIdentity/gradient-left.png'
import blurr from '../images/assets/BrandIdentity/blur-effect.png'
import backarrow from '../images/assets/BrandIdentity/backarrow.png'
import { Modal, ModalHeader } from 'react-bootstrap'
import CountrySelector from '../Components/Country Selector/CountrySelector'
import ChooseDM from './Digital Marketing/Choose'
import OurWorkDM from './Digital Marketing/OurWork'
import PackagesDM from './Digital Marketing/Packages'
import WeWorkDM from './Digital Marketing/WeWork'
import ReviewsDM from './Digital Marketing/Reviews'
const Brand = () => {
    const [showForm, setShowForm] = useState(false);
    // const [hasBeenClosed, setHasBeenClosed] = useState(false); 

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         if (!hasBeenClosed) { 
    //             setShowForm(true);
    //         }
    //     }, 3000);

      
    //     return () => clearTimeout(timer);
    // }, [hasBeenClosed]);

    const handleClose = () => {
        setShowForm(false);
        // setHasBeenClosed(true); 
    };
    const [showThankYou, setShowThankYou] = useState(false);
    const form = useRef();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        services: '',
        message: '',
        agreement: false,
    });
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [name]: newValue });
    };
    const [errors, setErrors] = useState({});
    const handleSubmit = (e) => {
        e.preventDefault();

        const validationErrors = validateForm(formData);
        console.log(Object.keys(validationErrors).length)
        if (Object.keys(validationErrors).length === 0) {
            setShowForm(false);
            setShowThankYou(true);
            const config = {
                SecureToken: '60d04b0a-78c3-401b-8683-4721c8069cd7',
                To: 'info@wurfelit.co',
                From: "getqoute@wurfelit.co",
                Subject: 'CONTACT FORM',
                Body: `Name :${formData.name}, 
               Email: ${formData.email}, 
               Company: ${formData.services}, 
               Country: ${formData.country.value}, 
               Phone: ${formData.phoneNumber}, 
               MESSAGE : ${formData.message}`
            }
            window.Email.send(config).then((e, c, p, d) => console.log(e, c, p, d))
        } else {
            setErrors(validationErrors);
        }
    };
    const validateForm = (data) => {
        const errors = {};

        if (!data.name || data.name.length < 3 || data.name.length > 25) {
            errors.name = 'Name should be between 3 and 25 characters*';
        }

        if (!data.email) {
            errors.email = 'Email is required*';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Invalid email format*';
        }

        if (!data.country) {
            errors.country = 'Country is required*';
        }
        if (!data.services) {
            errors.services = 'Services field is required';
        }
        if (!data.phoneNumber) {
            errors.phoneNumber = 'Phone number is required*';
        } else if (!/^\d{10}$/.test(data.phoneNumber)) {
            errors.phoneNumber = 'Invalid phone number format';
        }
        if (!data.agreement) {
            errors.agreement = 'You must agree to the terms and conditions';
        }
        if (!data.message) {
            errors.message = 'Message field is required';
        }
        return errors;
    };

    return (
        <>
            <div className="brand-header">
                <div className='upper' />
                <img src={left} alt="" className='left' />
                <div className='lower' />
                <img src={blurr} alt="" className='blurr' />
                <div className='brand-header-context'>
                    <h1>Your brand is what people say about you when you're not in the room. </h1>
                    <p>Jeff Bezos</p>
                    <button onClick={() => setShowForm(true)}>Let's Talk! 🙂</button>
                </div>
                <div className='brand-header-video'>
                    <video
                        src={headervideo}
                        playsInline autoPlay muted loop />
                </div>
            </div>

            <OurWorkDM />

            <PackagesDM />

            <WeWorkDM />

            <ChooseDM />

            <ReviewsDM />

            <Modal show={showForm} className='identity-modal'>
                <ModalHeader>
                    <div className='identity-modal-header'>
                        <h1 className='identity-modal-heading'>Let's talk business</h1>
                        <img src={backarrow} alt="" onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </div>
                </ModalHeader>
                <div className='identity-form'>
                    <form ref={form} onSubmit={handleSubmit} className='form'>
                        <div className='formcon'>
                            <div className='formdata'>
                                <label htmlFor="Name">Your Name*</label> {errors.name && <span className="error">{errors.name}</span>}
                                <input type="text"
                                    value={formData.name}
                                    onChange={handleChange}
                                    name="name"
                                    placeholder='Enter Your Name'
                                    className='name' />
                            </div>
                            <div className='formdata'>
                                <label htmlFor="Email">Contact email* </label> {errors.email && <span className="error">{errors.email}</span>}

                                <input type="text"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder='You@example.com'
                                    className='email' />
                            </div>
                        </div>
                        <div className='formcon'>
                            <div className='formdata'>
                                <label htmlFor="Number">Phone Number* </label> {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
                                <div className='inputs'>
                                    <CountrySelector setData={setFormData} data={formData} />
                                    <input type="text"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        placeholder='xxxxxxxxxx'
                                        className='input2' />
                                </div>
                            </div>
                            <div className='formdata'>
                                <label htmlFor="Services">Services Required*</label> {errors.services && <span className="error">{errors.services}</span>}
                                <select
                                    className='servicedrop'
                                    name="services"
                                    value={formData.services}
                                    defaultValue=""
                                    onChange={handleChange}
                                >
                                    <option value="" disabled>Enter Your Value</option>
                                    <option value="Brand Identity">Brand Identity</option>
                                    <option value="Digital Marketing">Digital Marketing</option>
                                </select>
                            </div>
                        </div>
                        <div className='formcon2'>
                            <div className='formdata'>
                                <label htmlFor="Services">Your Message*</label> {errors.message && <div className='error'>{errors.message}</div>}
                                <input
                                    type="text"
                                    name="message"
                                    style={{ verticalAlign: 'text-top' }}
                                    placeholder='Type your message...'
                                    value={formData.message}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        {errors.agreement && <span className="error">{errors.agreement}</span>}
                        <div className='identity-text'>
                            <input type="checkbox"
                                name='agreement'
                                checked={formData.agreement}
                                onChange={handleChange}
                                className='checkbox' />

                            By submitting this form, you hereby acknowledge and agree to the terms and conditions, as well as our privacy policy. Please be aware that we may collect and utilize your personal information as outlined in our privacy policy.
                        </div>
                        <button type='submit' className='sendbutton'>Send</button>
                    </form>
                </div>
            </Modal>

            <Modal show={showThankYou} className='identity-thankyou'>
                <div className='identity-thankyou'>
                    <div className='thankyoutxt'>
                        <h1>Thank You</h1>
                        <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} alt='' />
                        <p>We appreciate the time you've taken to get in touch and are committed
                            to responding as quickly as possible. Our team will review your message and you
                            can expect to hear back from us within <b>24-48 hours</b></p>
                    </div>
                </div>
            </Modal>

        </>
    )
}

export default Brand
