
import React, { useState } from 'react';
import divider from '../images/assets/divider.svg'
import faqbut from '../images/assets/faqbut.svg'

function FAQItem({ heading, content }) {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [isButtonRotated, setIsButtonRotated] = useState(false);
  const [isHeadingGrayscale, setIsHeadingGrayscale] = useState(false);

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
    setIsButtonRotated(!isButtonRotated);
    setIsHeadingGrayscale(!isHeadingGrayscale);
  };

  return (
    <div className="FAQ1" onClick={toggleContent}>
      <div className="FAQ1a">
        <h2 className={isHeadingGrayscale ? 'grayscale' : ''}>{heading}</h2>
        <button className={`faqButton ${isButtonRotated ? 'rotated' : ''}`} >
          <img src={faqbut} alt="" className='faqButtonimg' />
        </button>
      </div>
      <div className={`FAQ1b ${isContentVisible ? 'visible' : ''}`}>
        <img src={divider} alt="" />
        <p>{content}</p>
      </div>
    </div>
  );
}
function App() {
    return (
      <>
        <FAQItem
          heading="1. What industries does Tacit cater to?"
          content="Tacit caters to a wide range of industries including technology, finance, healthcare, e-commerce, manufacturing, and more."
        />
        <FAQItem
          heading="2. Does Tacit provide IT consulting services?"
          content="Yes, we offer IT consulting services to help businesses make informed technology decisions and optimize their IT infrastructure."
        />
        <FAQItem
          heading="3. How can I get started with Tacit?"
          content="Getting started with Tacit is simple. Reach out to us through our website or contact our sales team directly. We will schedule a consultation to understand your requirements and provide you with a customized plan of action. Let's embark on a journey of success together!"
        />
        <FAQItem
          heading="4. What makes Tacit different from other digital solutions providers?"
          content="Getting started with Tacit is simple. Reach out to us through our website or contact our sales team directly. We will schedule a consultation to understand your requirements and provide you with a customized plan of action. Let's embark on a journey of success together!"
        />
        <FAQItem
          heading="5. How will I be able to monitor the progress of my project?"
          content="We believe in transparent communication. You'll receive regular updates, and our team will be readily available to discuss any aspect of your project.
          "
        />
        <FAQItem
          heading="6. Can I see some of the work you've done before?"
          content="Absolutely! You can explore our portfolio to see some of the projects we've worked on. If you want to see more specific examples related to your industry or service need, please contact us."
        />
      </>
    );
  }
  
  export default App;
  