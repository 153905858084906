import React from 'react'
import { useState, useEffect } from 'react';
// import profile from '../images/assets/profile.png'
import BlogsData from '../Components/BlogsData';
import arrow from '../images/assets/downarrowhead.svg'
import searchbar from '../images/assets/searchnar.png'
const data = [
  {
    image: '',
    heading: 'Join us, ignite your potential, and shape!',
    description: 'At Tacit, we are constantly scouting for professionals who excel in their fields and bring unique perspectives. If you thrive in a fast-paced, innovation-driven environment, explore our current vacancies.',
  },
  {
    image: '',
    heading: 'Join us, ignite your potential, and shape!',
    description: 'At Tacit, we are constantly scouting for professionals who excel in their fields and bring unique perspectives. If you thrive in a fast-paced, innovation-driven environment, explore our current vacancies',
  },
  {
    image: '',
    heading: 'Join us, ignite your potential, and shape!',
    description: 'At Tacit, we are constantly scouting for professionals who excel in their fields and bring unique perspectives. If you thrive in a fast-paced, innovation-driven environment, explore our current vacancies.',
  },
  {
    image: '',
    heading: 'Join us, ignite your potential, and shape!',
    description: 'At Tacit, we are constantly scouting for professionals who excel in their fields and bring unique perspectives. If you thrive in a fast-paced, innovation-driven environment, explore our current vacancies',
  },
  {
    image: '',
    heading: 'Join us, ignite your potential, and shape!',
    description: 'At Tacit, we are constantly scouting for professionals who excel in their fields and bring unique perspectives. If you thrive in a fast-paced, innovation-driven environment, explore our current vacancies.',
  },
  {
    image: '',
    heading: 'Join us, ignite your potential, and shape!',
    description: 'At Tacit, we are constantly scouting for professionals who excel in their fields and bring unique perspectives. If you thrive in a fast-paced, innovation-driven environment, explore our current vacancies',
  },
  {
    image: '',
    heading: 'Join us, ignite your potential, and shape!',
    description: 'At Tacit, we are constantly scouting for professionals who excel in their fields and bring unique perspectives. If you thrive in a fast-paced, innovation-driven environment, explore our current vacancies.',
  },
  {
    image: '',
    heading: 'Join us, ignite your potential, and shape!',
    description: 'At Tacit, we are constantly scouting for professionals who excel in their fields and bring unique perspectives. If you thrive in a fast-paced, innovation-driven environment, explore our current vacancies',
  },
  {
    image: '',
    heading: 'Join us, ignite your potential, and shape!',
    description: 'At Tacit, we are constantly scouting for professionals who excel in their fields and bring unique perspectives. If you thrive in a fast-paced, innovation-driven environment, explore our current vacancies.',
  }

];
const Blogs = () => {
  const [blogsToShow, setBlogsToShow] = useState(6);
  const [showMore, setShowMore] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  useEffect(() => {
    const filtered = data.filter((blog) =>
      blog.heading.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredBlogs(filtered);
  }, [searchTerm]);
  const handleShowMore = () => {
    if (showMore) {
      setBlogsToShow(data.length);
    } else {
      setBlogsToShow(3);
    }
    setShowMore(!showMore);
  };
  return (
    <>
      <div className="blog1">
        <div className="blog1txt">
          <h1>Blog & Events</h1>
          <p>check out our blog for latest tips, tricks and happenings in business and design</p>
        </div>
        <div className="searchbar">
          <input
            type="text"
            placeholder="What are you looking for? "
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className='search-bar'
          />
        </div>
      </div>

      <div className="blog2">
        <BlogsData data={data.slice(0, blogsToShow)} />
        <div className="buttondiv"> <button className={`showmore ${showMore ? 'rotate-arrow' : ''}`} onClick={handleShowMore}>{showMore ? 'Show Less' : 'Show More'}&nbsp;<img src={arrow} /></button></div>

      </div>
    </>
  )
}

export default Blogs

