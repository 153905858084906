import React from "react";
import Home from '../src/Pages/Home'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import Navbar from '../src/Components/Navbar';
import Footer from '../src/Components/Footer';
import { Route, Routes, useLocation } from 'react-router-dom';
import Services from '../src/Pages/Services';
import Work from '../src/Pages/Work';
// import About from '../src/Pages/About';
// import Career from '../src/Pages/Career';
import Contact from '../src/Pages/Contact';
import Branding from '../src/Pages/Branding';
import WebDevelopment from '../src/Pages/WebDevelopment';
import SMM from '../src/Pages/SMM';
import Graphic from '../src/Pages/Graphic';
import Content from '../src/Pages/Content';
import Blogs from '../src/Pages/Blogs';
import Terms from './Components/Terms & Privacy/Terms';
import Privacy from './Components/Terms & Privacy/Privacy'
import Plans from './Components/Plans/Plans';
import Arista from './Components/Portfolios/Arista';
import Ibrahimi from './Components/Portfolios/Ibrahimi';
import LubeOne from './Components/Portfolios/LubeOne';
import Niche from './Components/Portfolios/Niche';
import One from './Components/Portfolios/One';
import UCI from './Components/Portfolios/UCI';
import Juicy from './Components/Portfolios/Juicy';
import Terra from './Components/Portfolios/Terra';
import Ansari from './Components/Portfolios/Ansari'
import Andrew from './Components/Portfolios/Andrew';
import Thankyou from './Components/Thankyou';
import Brand from "./Components/BrandIdentity/Brand";
import Butter from "./Components/Portfolios/Butter";
import Slick from "./Components/Portfolios/Slick";
import Digitax from "./Components/Portfolios/Digitax";
import Falcon from "./Components/Portfolios/Falcon";
import Moods from "./Components/Portfolios/Moods";
import BlueHorizon from "./Components/Portfolios/BlueHorizon";
// import AdsPage from "./Pages/AdsPage";
import ThankyouPayment from "./Pages/ThankyouPayment";


const App = () => {
  const location = useLocation(); // Get the current location
  const showNavbarAndFooter = location.pathname !== '/Ad'; // Determine if Navbar and Footer should be shown
  return (
    <>
      {showNavbarAndFooter && <Navbar />}
      <Routes>
        <Route path='/' element={<Home />} />
        {/* <Route path='/About' element={<About />} /> */}
        <Route path='/Services' element={<Services />} />
        <Route path='/Work' element={<Work />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Branding' element={<Branding />} />
        <Route path='/WebDevelopment' element={<WebDevelopment />} />
        <Route path='/Digital-Marketing' element={<SMM />} />
        <Route path='/Graphic' element={<Graphic />} />
        <Route path='/Content' element={<Content />} />
        <Route path='/Blogs' element={<Blogs />} />
        <Route path='/Terms' element={<Terms />} />
        <Route path='/Privacy' element={<Privacy />} />
        <Route path='/Plans' element={<Plans />} />
        <Route path='/Arista' element={<Arista />} />
        <Route path='/Ibrahimi' element={<Ibrahimi />} />
        <Route path='/Lube' element={<LubeOne />} />
        <Route path='/Niche' element={<Niche />} />
        <Route path='/One' element={<One />} />
        <Route path='/UCI' element={<UCI />} />
        <Route path='/Andrew' element={<Andrew />} />
        <Route path='/Juicy' element={<Juicy />} />
        <Route path='/TerraCosta' element={<Terra />} />
        <Route path='/Butter' element={<Butter />} />
        <Route path='/Slick' element={<Slick />} />
        <Route path='/Digitax' element={<Digitax />} />
        <Route path='/Falcon' element={<Falcon />} />
        <Route path='/Moods' element={<Moods />} />
        <Route path='/BlueHorizon' element={<BlueHorizon />} />
        <Route path='/Ansari' element={<Ansari />} />
        <Route path='/Thankyou' element={<Thankyou />} />
        <Route path='/Thank-You' element={<ThankyouPayment />} />
        <Route path='/Identity' element={<Brand />} />
        {/* <Route path='/Ad' element={<AdsPage />} /> */}
      </Routes>
      {showNavbarAndFooter && <Footer />}
    </>
  )
}

export default App;

