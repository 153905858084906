import React from 'react'
import webdev from '../images/assets/webdev.png'
import dash from '../images/assets/dash.svg'
import w1 from '../images/assets/w1.png'
import w2 from '../images/assets/w2.svg'
import w3 from '../images/assets/w3.svg'
import w4 from '../images/assets/w4.svg'
import w5 from '../images/assets/w5.svg'
import w6 from '../images/assets/w6.svg'
import w7 from '../images/assets/w7.svg'
import w8 from '../images/assets/w8.svg'
import w9 from '../images/assets/w9.svg'
import port from '../images/assets/port.png'
import cons from '../images/assets/cons.png'
const WebDevelopment = () => {
    return (
        <>
            <section id='header' className='d-flex align-items-center'>
                <div className='service1'>
                    <img src={webdev} className='service' style={{ zIndex: '-10' }} alt=''/>
                </div>
            </section>
            <div className='service2'>
                <div className='service2txt'>
                    <h1>Website Development</h1>
                    <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} alt=''/>
                    <p>Welcome to Tacit, the cutting-edge provider of world-class website development services. We deliver innovative, efficient, and future-ready digital solutions. Our team of seasoned experts is committed to creating impactful online presences tailored to your business needs.</p>
                </div>
                <div className='service2con'>
                    <div className='condiv'>
                        <div className='subdiv'>
                            <img src={w1} alt=''/>
                            <h1>Custom Website Development</h1>
                            <p>Our team of experts can develop highly responsive, dynamic, and interactive websites tailored to your unique needs.</p>
                        </div>
                        <div className='subdiv'>
                            <img src={w4} alt=''/>
                            <h1>CMS-based</h1>
                            <p>We provide robust CMS-based website development on platforms such as WordPress, Joomla, Drupal, etc., facilitating easy content management on your end.</p>
                        </div>
                        <div className='subdiv'>
                            <img src={w7} alt=''/>
                            <h1>UI/UX Design</h1>
                            <p> We focus on delivering superior user interfaces and user experiences, resulting in improved user engagement and higher conversion rates.</p>
                        </div>
                    </div>
                    <div className='condiv'>
                        <div className='subdiv'>
                            <img src={w2} alt=''/>
                            <h1>E-commerce Development</h1>
                            <p> We build comprehensive e-commerce platforms with user-friendly interfaces, secure payment gateways, and advanced features to increase your online sales.</p>
                        </div>
                        <div className='subdiv'>
                            <img src={w5} alt=''/>
                            <h1>Mobile-Friendly </h1>
                            <p>We develop mobile-optimized websites, ensuring a seamless browsing experience on smartphones and tablets.</p>
                        </div>
                        <div className='subdiv'>
                            <img src={w8} alt=''/>
                            <h1> Maintenance and Support</h1>
                            <p>Our ongoing website maintenance and support services keep your website updated, secure, and running smoothly.</p>
                        </div>
                    </div>
                    <div className='condiv'>
                        <div className='subdiv'>
                            <img src={w3} alt=''/>
                            <h1>Responsive Web Design</h1>
                            <p>Our responsive web design ensures your site works perfectly on all devices, enhancing your users' experience and accessibility.</p>
                        </div>
                        <div className='subdiv'>
                            <img src={w6} alt=''/>
                            <h1>SEO Optimized Websites</h1>
                            <p>We create SEO-friendly websites that enhance your online visibility, driving more organic traffic to your site.</p>
                        </div>
                        <div className='subdiv'>
                            <img src={w9} alt=''/>
                            <h1>Web App Development</h1>
                            <p> We build interactive and efficient web applications that cater to your specific business needs.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='service3'>
                <div className='service3txt'>
                    <h1>Ready to create a website that transforms your digital presence?</h1>
                    <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} alt=''/>
                    <p>Invest in our website development services today and step into the future of digital experience. With Tacit by your side, rest assured that your online presence is in the hands of experts. We aim to deliver solutions that make you stand out in today's competitive digital landscape.</p>
                </div>
                <div className='request'>
                    <a href="/Work"><button className='req'>Check our portfolio <img src={port} className='reqimg' /></button></a>
                    <a href="/Contact"><button className='req'>Free Consultation <img src={cons} className='reqimg' /></button></a>
                </div>
            </div>
        </>
    )
}

export default WebDevelopment
