import React, { useState } from 'react'
import face from '../../images/assets/BrandIdentity/weworkface.png'
import basic from '../../images/assets/BrandIdentity/basic.svg'
import standard from '../../images/assets/BrandIdentity/standard.svg'
import premium from '../../images/assets/BrandIdentity/premium.svg'
import pointline from '../../images/assets/BrandIdentity/point-basic.svg'
import pointlinemob from '../../images/assets/BrandIdentity/point-basic-phone.svg'
import pointpremium from '../../images/assets/BrandIdentity/point-premium.png'
import { Modal, ModalBody } from 'react-bootstrap'
import { ModalHeader } from 'react-bootstrap'
import basicmodal from '../../images/assets/BrandIdentity/basicmodal.png'
import standardmodal from '../../images/assets/BrandIdentity/standardmodal.svg'
import premiummodal from '../../images/assets/BrandIdentity/premiummodal.svg'
import backarrow from '../../images/assets/BrandIdentity/backarrow.png'
import checkimage from '../../images/assets/BrandIdentity/completed.svg'
import rocketimg from '../../images/assets/BrandIdentity/rocket.svg'
import time from '../../images/assets/BrandIdentity/time.svg'
import check from '../../images/assets/BrandIdentity/checkmark.png'

const WeWork = () => {

    const [showBasicModal, setshowBasicModal] = useState(false)
    const [showStandardModal, setshowStandardModal] = useState(false)
    const [showPremiumModal, setshowPremiumModal] = useState(false)



    const basicsteps = [
        {
            para: 'Step &nbsp;1',
            check: checkimage,
            heading: 'Initial Questionnaire',
            point: '• Discovery',
            pointb: '• Strategy'
        },
        {
            para: 'Step 2',
            check: checkimage,
            heading: 'Concepts Designing',
            point: '• Creativity',
            pointb: '• Concepts'
        },
        {
            para: 'Step 3',
            check: checkimage,
            heading: 'Logo Presentation',
            point: '• Presentation',
            pointb: '• Feedback'
        },
        {
            para: 'Step 4',
            check: rocketimg,
            heading: 'Finalization',
            point: '• Approval',
            pointb: '• Delivery'
        },

    ]

    const Deliverables = [
        {
            para: 'Logo HD File Logo'
        },
        {
            para: 'Print Ready File'

        },
        {
            para: 'Logo Transparent File'
        },
        {
            para: 'Logo Source File'
        }

    ]

    const standardsteps = [
        {
            para: 'Step &nbsp;1',
            check: checkimage,
            heading: 'Brand Goals Consultation',
            point: '• Insightful Analysis',
            pointb: '• Strategic Planning'
        },
        {
            para: 'Step 2',
            check: checkimage,
            heading: 'Logo Design',
            point: '• Creative Concepts',
            pointb: '• Customization'
        },
        {
            para: 'Step 3',
            check: checkimage,
            heading: 'Brand Pattern Concepts',
            point: '• Unique Patterns',
            pointb: '• Visual Harmony'
        },
        {
            para: 'Step 4',
            check: checkimage,
            heading: 'Basic Branding Presentation',
            point: '• 3-5 Mockups',
            pointb: '• Visual Showcase',
            pointc: '• Presentation Options'
        },
        {
            para: 'Step 5',
            check: rocketimg,
            heading: 'Finalization',
            point: '• Refinement',
            pointb: '• Completion '
        },
    ]

    const standardDeliverables = [
        {
            para: 'Basic Brand Style Guide',
        },
        {
            para: 'Logo with mockups',
        },
        {
            para: 'All files of Logo (PNG, JPEG, Source file, HD File)',
        },
        {
            para: 'Branding Presentation',
        },
        {
            para: 'Business Card Design + 2 people Printable file for business cards',
        },
        {
            para: 'Social Platforms Dp & Cover (fb,  Insta)',
        },

    ]

    const premiumsteps = [
        {
            para: 'Step &nbsp;1',
            check: checkimage,
            heading: 'Discovery Session',
            point: '• Exploratory',
            pointb: '• Insightful'
        },
        {
            para: 'Step 2',
            check: checkimage,
            heading: 'Research & Analysis',
            point: '• In-depth(Competitor)',
            pointb: '• Strategic'
        },
        {
            para: 'Step 3',
            check: checkimage,
            heading: 'Brainstorming & Sketching',
            point: '• Creative',
            pointb: '• Ideation'
        },
        {
            para: 'Step 4',
            check: checkimage,
            heading: 'Concepts Conversions/Pattern',
            point: '• Transformative',
            pointb: '• Refinement',
        },
        {
            para: 'Step 5',
            check: rocketimg,
            heading: 'Final presentation',
            point: '• Visual',
            pointb: '• Showcase mockups'
        },
    ]
    const premiumDeliverables = [
        {
            para: 'Extensive Brand Style Guide with do’s and don’ts',
        },
        {
            para: 'Logo with mockups',
        },
        {
            para: 'All files of Logo (PNG, JPEG, Source file, HD File)',
        },
        {
            para: 'Branding Presentation (8-10 Mockups)',
        },
        {
            para: 'Business Card Design + 5 people Printable file for business cards',
        },
        {
            para: 'Social Platforms Dp & Cover (fb,  Insta, LinkedIn, X)',
        },
        {
            para: 'Letterhead Design (Print Ready File)',
        },
        {
            para: 'Small Envelope Design (Print Ready File)',
        },
        {
            para: 'Pen Design (Print Ready File)',
        },
        {
            para: 'Clock Design (Print Ready File)',
        },
        {
            para: 'Stamp Design (Print Ready File)',
        },

    ]
    return (
        <>
            <div className="wework-main">
                <div className="wework-context">
                    <img src={face} alt="" />
                    <h1>How we work?</h1>
                    <p>If you’re creating a start-up, building a small business, or dreaming bigger, Tacit can help you create a unique brand identity.</p>
                </div>

                <div className='package-basic'>
                    <img src={basic} alt="" />
                    <h2>Basic Identity package</h2>
                    <p>Share details about your brand, respond to brand-related queries, and kickstart the creative process within minutes</p>
                    <button onClick={() => setshowBasicModal(true)}>More Info</button>
                    <img src={pointline} alt="" onClick={() => setshowBasicModal(true)} style={{ cursor: 'pointer', zIndex: '2' }} className='line-web' />
                    <img src={pointlinemob} alt="" onClick={() => setshowBasicModal(true)} style={{ cursor: 'pointer' }} className='line-mob' />
                    <div className='gradient-left' />
                </div>
                <div className='package-standard'>
                    <img src={pointlinemob} alt="" style={{ rotate: '180deg', cursor: 'pointer' }} onClick={() => setshowStandardModal(true)} className='line-mob' />
                    <img src={pointline} alt="" style={{ rotate: '180deg', cursor: 'pointer' }} onClick={() => setshowStandardModal(true)} className='line-web' />
                    <img src={standard} alt="" />
                    <h2>Standard Identity Package</h2>
                    <p>Our designers will collaborate to give your company a unique brand identity. You will receive your first design within 24 hours.</p>
                    <button onClick={() => setshowStandardModal(true)}>More Info</button>
                    <div className='gradient-mid' />
                </div>
                <div className='package-premium'>
                    <img src={premium} alt="" />
                    <h2>Premium Identity Package</h2>
                    <p>Choose the design that you want, and we'll help with implementation and delivery of all brand assets for instant use. </p>
                    <button onClick={() => setshowPremiumModal(true)}>More Info</button>
                    <img src={pointpremium} alt="" onClick={() => setshowPremiumModal(true)} style={{ cursor: 'pointer' }} className='line-web' />
                    <img src={pointlinemob} alt="" onClick={() => setshowPremiumModal(true)} style={{ cursor: 'pointer' }} className='line-mob' />
                </div>
            </div>


            <Modal
                show={showBasicModal} className='basicmodal'>
                <ModalHeader>
                    <div className='header-text'>
                        <img src={basicmodal} alt="" />
                        <p>Basic Identity package</p>
                    </div>
                    <img src={backarrow} alt="" onClick={() => setshowBasicModal(false)} style={{ cursor: 'pointer' }} />
                </ModalHeader>
                <ModalBody>
                    <div className='modal-body-left'>
                        {basicsteps.map((basicsteps, index) => (
                            <div key={index} className='modal-steps'>
                                <div className='steps-left'>
                                    <p dangerouslySetInnerHTML={{ __html: basicsteps.para }}></p>
                                    <img src={basicsteps.check} alt="" />
                                </div>
                                <div>
                                    <h2>{basicsteps.heading}</h2>
                                    <h3>{basicsteps.point} <br /> {basicsteps.pointb}</h3>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='modal-body-right'>
                        <div className='right-upper'>
                            <img src={time} alt="" />
                            <h2>Approximate Time</h2>
                            <p>5 Working Days</p>
                        </div>
                        <div className='right-middle'>
                            <h2>Deliverables:</h2>
                            {Deliverables.map((Deliverables, index) => (
                                <div key={index} className='right-middle-subsection'>
                                    <img src={check} alt='' />
                                    <p>{Deliverables.para}</p>
                                </div>

                            ))}
                        </div>
                        <div className='right-lower'>
                            <h2>$499</h2>
                            <a href="https://buy.stripe.com/fZe9Ei3rObL99I44gQ" target="_blank" rel="noopener noreferrer"><button>Buy Now</button></a>
                        </div>
                    </div>
                </ModalBody>
            </Modal>


            <Modal
                show={showStandardModal} className='standardmodal'>
                <ModalHeader>
                    <div className='header-text'>
                        <img src={standardmodal} alt="" />
                        <p>Standard Identity package</p>
                    </div>
                    <img src={backarrow} alt="" onClick={() => setshowStandardModal(false)} style={{ cursor: 'pointer' }} />
                </ModalHeader>
                <ModalBody>
                    <div className='modal-body-left'>
                        {standardsteps.map((standardsteps, index) => (
                            <div key={index} className='modal-steps'>
                                <div className='steps-left'>
                                    <p dangerouslySetInnerHTML={{ __html: standardsteps.para }}></p>
                                    <img src={standardsteps.check} alt="" />
                                </div>
                                <div>
                                    <h2>{standardsteps.heading}</h2>
                                    <h3>{standardsteps.point} <br /> {standardsteps.pointb} <br /> {standardsteps.pointc}</h3>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='modal-body-right'>
                        <div className='right-upper'>
                            <img src={time} alt="" />
                            <h2>Approximate Time</h2>
                            <p>2 weeks</p>
                        </div>
                        <div className='right-middle'>
                            <h2>Deliverables:</h2>
                            {standardDeliverables.map((standardDeliverables, index) => (
                                <div key={index} className='right-middle-subsection'>
                                    <img src={check} alt='' />
                                    <p>{standardDeliverables.para}</p>
                                </div>

                            ))}
                        </div>
                        <div className='right-lower'>
                            <h2>$1,499</h2>
                            <a href="https://buy.stripe.com/28obMq8M8g1p4nK5kV" target="_blank" rel="noopener noreferrer"><button>Buy Now</button></a>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                show={showPremiumModal} className='premiummodal'>
                <ModalHeader>
                    <div className='header-text'>
                        <img src={premiummodal} alt="" />
                        <p>Premium Identity package</p>
                    </div>
                    <img src={backarrow} alt="" onClick={() => setshowPremiumModal(false)} style={{ cursor: 'pointer' }} />
                </ModalHeader>
                <ModalBody>
                    <div className='modal-body-left'>
                        {premiumsteps.map((premiumsteps, index) => (
                            <div key={index} className='modal-steps'>
                                <div className='steps-left'>
                                    <p dangerouslySetInnerHTML={{ __html: premiumsteps.para }}></p>
                                    <img src={premiumsteps.check} alt="" />
                                </div>
                                <div>
                                    <h2>{premiumsteps.heading}</h2>
                                    <h3>{premiumsteps.point} <br /> {premiumsteps.pointb}</h3>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='modal-body-right'>
                        <div className='right-upper'>
                            <img src={time} alt="" />
                            <h2>Approximate Time</h2>
                            <p>4 Weeks</p>
                        </div>
                        <div className='right-middle'>
                            <h2>Deliverables:</h2>
                            {premiumDeliverables.map((premiumDeliverables, index) => (
                                <div key={index} className='right-middle-subsection'>
                                    <img src={check} alt='' />
                                    <p>{premiumDeliverables.para}</p>
                                </div>

                            ))}
                        </div>
                        <div className='right-lower'>
                            <h2>$2,499</h2>
                            <a href="https://buy.stripe.com/fZe3fUd2o7uT2fCcNo" target="_blank" rel="noopener noreferrer"><button>Buy Now</button></a>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default WeWork
