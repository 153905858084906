import React from 'react'
import stars from '../../images/assets/BrandIdentity/stars.png'
import aristasolar from '../../images/assets/BrandIdentity/aristasolar.png'
import moodsrev from '../../images/assets/BrandIdentity/moodsrev.png'
import nicherev from '../../images/assets/BrandIdentity/nicherev.png'
import onerev from '../../images/assets/BrandIdentity/onerev.png'
import falconrev from '../../images/assets/BrandIdentity/falconrev.png'
import slickrev from '../../images/assets/BrandIdentity/slickrev.png'
import ucirev from '../../images/assets/BrandIdentity/ucirev.png'
import juicyrev from '../../images/assets/BrandIdentity/juicyrev.png'
import bluerev from '../../images/assets/BrandIdentity/bluerev.png'
import butterrev from '../../images/assets/BrandIdentity/butterrev.png'
import andrewrev from '../../images/assets/BrandIdentity/andrewrev.png'
import digitaxrev from '../../images/assets/BrandIdentity/digitaxrev.png'
import Slider from 'react-slick'
const Reviews = () => {

    const grid = [
        {
            logo: aristasolar,
            para: 'Tacit superbly rebranded Arista Solar, capturing our essence with a vibrant identity that reflects our innovative spirit in the solar industry.'
        },
        {
            logo: moodsrev,
            para: "With Tacit's masterful rebranding, we went from Moods Craft to Moods, enhancing our identity and aligning perfectly with our mission."
        },
        {
            logo: nicherev,
            para: 'Tacit brilliantly transformed our luxury home store into a standout brand in Islamabad with elegance and creativity.'
        },
        {
            logo: onerev,
            para: 'Tacit was exceptional in distilling the essence of One Network, making sure that our branding was consistent & successfully drew in potential customers.'
        },
        {
            logo: falconrev,
            para: "As a client of Tacit, I'm thoroughly impressed.Their team masterfully intertwined our vision with a dynamic identity that truly sets us apart in the travel industry. "
        },
        {
            logo: slickrev,
            para: "Tacit masterfully crafted Slick's standout brand identity in the industrial lubricants market, highlighting our commitment to reliability."
        },

    ]

    const secondgrid = [
        {
            logo: ucirev,
            para: "UCI's new branding by Tacit brilliantly captures our essence, blending our construction expertise with a fresh, innovative visual identity."
        },
        {
            logo: juicyrev,
            para: 'The vibrant yellow and bold black branding created by Tacit has given Juicy Grill a new look and enhanced our market appeal.'
        },
        {
            logo: bluerev,
            para: "Tacit nailed it! They crafted Horizon Pools' brand identity perfectly, blending our essence with a captivating appeal. Absolute satisfaction! "
        },
        {
            logo: butterrev,
            para: 'Tacit helped us create a flawless fusion of modern style and technological know-how, strengthening our position in the POS sector.'
        },
        {
            logo: andrewrev,
            para: 'We gained a competitive advantage in the paint industry because Tacit-IT instilled strategic insight and creative excellence into our brand. '
        },
        {
            logo: digitaxrev,
            para: 'Tacit brought our vision of straightforward, user-friendly tax filing to life by transforming Digitax with a distinct, clear brand identity.'
        },

    ]
    const mobgrid = [
        {
            logo: aristasolar,
            para: 'Tacit superbly rebranded Arista Solar, capturing our essence with a vibrant identity that reflects our innovative spirit in the solar industry.'
        },
        {
            logo: moodsrev,
            para: "With Tacit's masterful rebranding, we went from Moods Craft to Moods, enhancing our identity and aligning perfectly with our mission."
        },
    ]

    const mobsecondgrid = [
        {
            logo: nicherev,
            para: 'Tacit brilliantly transformed our luxury home store into a standout brand in Islamabad with elegance and creativity.'
        },
        {
            logo: onerev,
            para: 'Tacit was exceptional in distilling the essence of One Network, making sure that our branding was consistent & successfully drew in potential customers.'
        },  
    ]
    const thirddgrid = [
        {
            logo: falconrev,
            para: "As a client of Tacit, I'm thoroughly impressed.Their team masterfully intertwined our vision with a dynamic identity that truly sets us apart in the travel industry. "
        },
        {
            logo: slickrev,
            para: "Tacit masterfully crafted Slick's standout brand identity in the industrial lubricants market, highlighting our commitment to reliability."
        },
    ]

    const fourthgrid = [
        {
            logo: ucirev,
            para: "UCI's new branding by Tacit brilliantly captures our essence, blending our construction expertise with a fresh, innovative visual identity."
        },
        {
            logo: juicyrev,
            para: 'The vibrant yellow and bold black branding created by Tacit has given Juicy Grill a new look and enhanced our market appeal.'
        },
    ]

    const fifthgrid = [
        {
            logo: bluerev,
            para: "Tacit nailed it! They crafted Horizon Pools' brand identity perfectly, blending our essence with a captivating appeal. Absolute satisfaction! "
        },
        {
            logo: butterrev,
            para: 'Tacit helped us create a flawless fusion of modern style and technological know-how, strengthening our position in the POS sector.'
        },
    ]

    const sixthgrid = [
        {
            logo: andrewrev,
            para: 'We gained a competitive advantage in the paint industry because Tacit-IT instilled strategic insight and creative excellence into our brand. '
        },
        {
            logo: digitaxrev,
            para: 'Tacit brought our vision of straightforward, user-friendly tax filing to life by transforming Digitax with a distinct, clear brand identity.'
        },

    ]

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    }

    return (
        <>
            <div className="brandreviews-main">
                <div className="brandreviews-context">
                    <h1>Hear what our clients say about us</h1>
                    <p>Brand identity is the art of storytelling; it's where every color, shape, and word paints the unique story of who you are and what you stand for.</p>
                </div>
                <div className='brandreview-main'>
                    <Slider {...settings}>
                        <div>
                            <div className="brandreviews-grid">
                                {grid.map((grid, index) => (
                                    <div className='brandreview' key={index}>
                                        <div className='brandreview-upper'>
                                            <img src={grid.logo} alt="" />
                                            <img src={stars} alt="" />
                                        </div>
                                        <div className='brandreview-p'>
                                            <p>{grid.para}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className="brandreviews-grid">
                                {secondgrid.map((secondgrid, index) => (
                                    <div className='brandreview' key={index}>
                                        <div className='brandreview-upper'>
                                            <img src={secondgrid.logo} alt="" />
                                            <img src={stars} alt="" />
                                        </div>
                                        <div className='brandreview-p'>
                                            <p>{secondgrid.para}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Slider>
                </div>


                <div className='brandreview-main-mob'>
                    <Slider {...settings}>
                        <div>
                            <div className="brandreviews-grid-mob">
                                {mobgrid.map((mobgrid, index) => (
                                    <div className='brandreview' key={index}>
                                        <div className='brandreview-upper'>
                                            <img src={mobgrid.logo} alt="" />
                                            <img src={stars} alt="" />
                                        </div>
                                        <div className='brandreview-p'>
                                            <p>{mobgrid.para}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className="brandreviews-grid-mob">
                                {mobsecondgrid.map((mobsecondgrid, index) => (
                                    <div className='brandreview' key={index}>
                                        <div className='brandreview-upper'>
                                            <img src={mobsecondgrid.logo} alt="" />
                                            <img src={stars} alt="" />
                                        </div>
                                        <div className='brandreview-p'>
                                            <p>{mobsecondgrid.para}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className="brandreviews-grid-mob">
                                {thirddgrid.map((secondgrid, index) => (
                                    <div className='brandreview' key={index}>
                                        <div className='brandreview-upper'>
                                            <img src={secondgrid.logo} alt="" />
                                            <img src={stars} alt="" />
                                        </div>
                                        <div className='brandreview-p'>
                                            <p>{secondgrid.para}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className="brandreviews-grid-mob">
                                {fourthgrid.map((secondgrid, index) => (
                                    <div className='brandreview' key={index}>
                                        <div className='brandreview-upper'>
                                            <img src={secondgrid.logo} alt="" />
                                            <img src={stars} alt="" />
                                        </div>
                                        <div className='brandreview-p'>
                                            <p>{secondgrid.para}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className="brandreviews-grid-mob">
                                {fifthgrid.map((secondgrid, index) => (
                                    <div className='brandreview' key={index}>
                                        <div className='brandreview-upper'>
                                            <img src={secondgrid.logo} alt="" />
                                            <img src={stars} alt="" />
                                        </div>
                                        <div className='brandreview-p'>
                                            <p>{secondgrid.para}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className="brandreviews-grid-mob">
                                {sixthgrid.map((secondgrid, index) => (
                                    <div className='brandreview' key={index}>
                                        <div className='brandreview-upper'>
                                            <img src={secondgrid.logo} alt="" />
                                            <img src={stars} alt="" />
                                        </div>
                                        <div className='brandreview-p'>
                                            <p>{secondgrid.para}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>

        </>
    )
}

export default Reviews
